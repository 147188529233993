<template>
  <div class="card full-height bg-ligth">
    <div class="card-header">
      <h3 class="mb-0">
        {{ lista_campos != "" ? "Editar" : "Criar" }} Paciente
      </h3>
    </div>
    <div class="card-body">
      <div class="col-md-12">
        <div class="form">
          <div class="row form-group">
            <div class="col-md-5">
              <label for="nome" class="col-md-12">{{
                $t("NOMBRE.NOME") 
              }}*</label>
              <input required="" type="text" class="form-control" id="nome" v-model="form.nome"
                placeholder="Digite o nome..." />
            </div>
            <div class="col-md-4">
              <label for="sobrenome" class="col-md-12">{{
                $t("SOBRENOME.NOME")
              }}*</label>
              <input required="" type="text" class="form-control" id="sobrenome" v-model="form.sobrenome"
                placeholder="Digite o Sobrenome..." />
            </div>
            <div class="col-md-3">
              <label class="col-md-12">Tipo de Paciente:*</label>
              <select v-model="form.tipo_paciente_id" class="form-control">
                <option value="1"> Externo </option>
                <option value="2"> Funcionario UCP </option>
                <option value="3"> Alumno UCP </option>
              </select>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-3">
              <label class="col-md-12">{{ $t("DATA.NOME") }}*</label>
              <input type="date" class="form-control" id="form_control_1" v-model="form.data_nascimento" />
            </div>
            <div class="col-md-2">
              <label class="col-md-12">{{ $t("DATA.IDADE") }}</label>
              <input type="text" disabled class="form-control" id="form_control_1" v-model="idade" />
            </div>

            <div class="col-md-2">
              <label class="col-md-12">Sexo:*</label>
              <select v-model="form.sexo_id" class="form-control">
                <option></option>
                <option value="3">Masculino</option>
                <option value="4">Feminino</option>
              </select>
            </div>

            <div class="col-md-2">
              <label class="col-md-12">Estado civil:</label>
              <select v-model="form.estado_civil_id" class="form-control">
                <option></option>
                <option value="1">Separado(a)</option>
                <option value="2">Casado(a)</option>
                <option value="5">Solteiro(a)</option>
                <option value="12">Divorciado(a)</option>
                <option value="3">Viuvo(a)</option>
              </select>
            </div>

            <div class="col-md-2">
              <label>{{ $t("PROFESION.NOME") }}</label>
              <select v-model="form.profissao_id" class="form-control">
                <option></option>
                <option v-for="lista in lista_profissoes" :key="lista.id" :value="lista.id">
                  {{ lista.label }}
                </option>
              </select>
              <!-- <treeselect
                :multiple="false"
                required
                v-model="form.profissao_id"
                :options="lista_profissoes"
              /> -->
            </div>
            <div class="col-md-1" style="padding-top: 2.2%">
              <button v-b-modal.Profissao class="btn btn-light-primary">
                <span class="fas fa-plus"></span>
              </button>

              <b-modal centered ref="Profissao" id="Profissao" title="Nueva Profesión">
                <div class="my-4 form">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="sobrenome" class="col-md-12">{{
                        $t("NOMBRE.NOME")
                      }}</label>
                      <input required="" type="text" class="form-control" id="sobrenome" v-model="form_profissao.nome"
                        placeholder="Escriba el nombre..." />
                    </div>
                  </div>
                </div>
                <template #modal-footer>
                  <div class="text-center w-100">
                    <button @click="confirm('cria una profission para')" class="btn btn-primary">
                      {{ $t("G.NOME") }}
                      <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                    </button>
                  </div>
                </template>
              </b-modal>
            </div>



          </div>
          <div class="row form-group">
            <div class="col-md-3 pull-left">
              <div class="md-radio-inline">
                <label class="col-md-12">Tiene Documento?:*</label>
                <div class="space d-flex">
                  <b-form-radio :inline="true" value="1" v-model="form.possui_documento">
                    Si
                  </b-form-radio>

                  <b-form-radio :inline="true" value="0" v-model="form.possui_documento">
                    No
                  </b-form-radio>

                </div>
              </div>
            </div>
            <div class="col-md-3" v-if="form.possui_documento == 1">
              <label for="rg" class="col-md-12">RG/CI:*</label>
              <input type="text" class="form-control" id="rg" v-model="form.rg" placeholder="Rg ou CI sin puntos" />
            </div>
            <div class="col-md-3" v-if="form.possui_documento == 1 && form.naturalidade_pais == 1">
              <label for="cpf" class="col-md-12">CPF:</label>
              <input type="text" class="form-control" id="cpf" v-model="form.cpf" placeholder="cpf sin puntos" />
            </div>

            <div v-if="form.perfil_id == 2" class="col-md-3 pull-left">
              <label>Login:*</label>
              <input v-model="form.login" class="form-control" />
            </div>
          </div>

          <div class="row form-group">
            <h4 class="col-md-12">Naturalidad</h4>

            <div class="col-md-4">
              <label class="col-md-12">País:</label>

              <treeselect id="nacionalidade_id" :multiple="false" :options="lista_pais"
                v-model="form.naturalidade_pais" />

            </div>
            <div class="col-md-4">
              <label v-if="form.naturalidade_pais == 1" class="col-md-12">Estado:</label>
              <label v-else="form.naturalidade_pais == 1" class="col-md-12">Departamento:</label>
              <treeselect :options="lista_estados_nacionalidade" :disabled="!form.naturalidade_pais" :multiple="false"
                v-model="form.naturalidade_estado" />
            </div>
            <div class="col-md-4">
              <label class="col-md-12">{{ $t("CIDADE.NOME") }}</label>
              <treeselect :disabled="!form.naturalidade_estado" :multiple="false" v-model="form.naturalidade_cidade"
                :options="lista_cidades_nacionalidade" />
            </div>
          </div>

          <div class="row form-group">
            <h4 class="col-md-12">Contacto</h4>
            <div class="col-md-4 pull-left">
              <label for="telefone1" class="col-md-12">{{
                $t("TELEFONO1.NOME")
              }}</label>
              <input type="text" class="form-control" id="telefone1" v-model="form.telefone1"
                placeholder="Digite o telefone/celular..." />
            </div>
            <div class="col-md-4 pull-left">
              <label for="telefone2" class="col-md-12">{{
                $t("TELEFONO2.NOME")
              }}</label>
              <input type="text" class="form-control" id="telefone2" v-model="form.telefone2"
                placeholder="Digite o telefone/celular..." />
            </div>

            <div class="col-md-4 pull-left">
              <label>Email:</label>
              <input v-model="form.email" class="form-control" />
            </div>

          </div>




          <div class="row form-group">
            <h4 class="col-md-12">Dirección</h4>
            <div class="col-md-2">
              <label class="col-md-12">CEP:</label>
              <input type="text" class="form-control" v-model="form.cep" placeholder="CEP..." />
            </div>
            <div class="col-md-5">
              <label class="col-md-12">{{ $t("ENDERECO.NOME") }}</label>
              <input type="text" class="form-control" v-model="form.rua" placeholder="Rua ...." />
            </div>
            <div class="col-md-2">
              <label for="endereco_numero" class="col-md-12">{{
                $t("NUMERO.NOME")
              }}</label>
              <input type="text" class="form-control" id="endereco_numero" v-model="form.numero"
                placeholder="numero ...." />
            </div>
            <div class="col-md-3">
              <label for="endereco_complemento" class="col-md-12">Complemento:</label>
              <input type="text" class="form-control" id="endereco_complemento" v-model="form.complemento"
                placeholder="Escriba el complemento..." />
            </div>
          </div>
          <div class="row form-group">

            <div class="col-md-2">
              <label for="pais_id" class="col-md-12">País:</label>
              <treeselect id="nacionalidade_id" :multiple="false" :options="lista_pais" v-model="form.pais_id" />
            </div>

            <div class="col-md-3">
              <label class="col-md-12">{{ $t("ESTADO2.NOME") }}</label>
              <treeselect :options="lista_estados_endereco" :disabled="!form.pais_id" :multiple="false"
                v-model="form.estado_id" />
            </div>

            <div class="col-md-3">
              <label class="col-md-12">{{ $t("CIDADE.NOME") }}</label>
              <treeselect :options="lista_cidades_endereco" :disabled="!form.estado_id" :multiple="false"
                v-model="form.cidade_id" />
            </div>

            <div class="col-md-4">
              <label for="endereco_bairro" class="col-md-12">{{
                $t("BAIRRO.NOME")
              }}</label>

              <treeselect :options="lista_bairros_endereco" :disabled="!form.cidade_id" :multiple="false"
                v-model="form.bairro_id" />
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3">
              <label for="endereco_bairro" class="col-md-12">{{
                $t("BAIRRO.NOME")
              }}</label>
              <input type="text" class="form-control" id="endereco_bairro" v-model="form.bairro"
                placeholder="Digite o bairro..." />
            </div>
            <div class="col-md-9 pull-left">
              <label for="descricao" class="col-md-12">{{ $t("DESCRICAO.NOME") }}:</label>
              <input type="text" class="form-control" id="descricao" v-model="form.descricao"
                placeholder="Digite a descricao..." />
            </div>
          </div>
          <div class="row form-group col-md-12">
            <div class="col-md-3 pull-left">
              <div class="md-radio-inline">
                <label>{{ $t("ESTADO.NOME") }}</label>
                <div class="space d-flex">
                  <b-form-radio :inline="true" value="1" v-model="form.status">
                    {{ $t("ATIVO.NOME") }}
                  </b-form-radio>

                  <b-form-radio :inline="true" value="2" v-model="form.status">
                    {{ $t("DESATIVADO.NOME") }}
                  </b-form-radio>

                  <b-form-radio :inline="true" value="3" v-model="form.status">
                    {{ $t("SUSPENSO.NOME") }}
                  </b-form-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="form-group row">
          <div class="col-md-12 text-center">
            <button @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')" class="btn btn-primary"
              :disabled="verif">
              {{ $t("GUARDARDATOS.NOME") }}
              <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
            </button>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
</template>
  
<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      form: {
        nome: "",
        sobrenome: "",
        possui_documento: 1,
        cpf: null,
        rg: null,
        data_nascimento: null,
        cep: null,
        rua: null,
        numero: null,
        complemento: null,
        bairro: null,
        email: null,
        status: null,
        profissao_id: null,
        sexo_id: "",
        login: null,
        naturalidade_cidade: null,
        naturalidade_estado: null,
        naturalidade_pais: 3,
        cidade_id: null,
        estado_id: null,
        pais_id: 3,
        telefone1: "",
        telefone2: "",
        perfil_id: 3,
        tipo_paciente_id: 1,
        estado_civil_id: null,
        descricao: "",
        bairro_id: null
      },
      form_profissao: {
        nome: "",
      },
      idade: null,
      paramestado: "",
      value: "",
      loading: false,
      verif: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Paciente" }]);
  },

  watch: {
    'form.naturalidade_pais'(value) {
      this.getEstadosNacionalidade(value)
    },
    'form.naturalidade_estado'(value) {
      this.getCidadesNacionalidade(value)
    },
    'form.pais_id'(value) {
      this.getEstadosEndereco(value)
    },
    'form.estado_id'(value) {
      this.getCidadesEndereco(value)
    },
    'form.cidade_id'(value) {
      this.lista_cidades_endereco.forEach(e => {
        if (e.id == value) {
          this.getBairrosEndereco(e.codigo_departamento, e.codigo_distrito)
        }
      });
    },
    'form.data_nascimento'(value) {
      this.calcularIdade(this.form.data_nascimento)
    },
  },
  computed: {
    lista_aluno() {
      return this.$store.state.sistema.lista_aluno;
    },
    lista_pais() {
      return this.$store.state.configEnderecos.lista_pais;
    },
    lista_estados_endereco() {
      return this.$store.state.configEnderecos.lista_estados_endereco;
    },
    lista_estados_nacionalidade() {
      return this.$store.state.configEnderecos.lista_estados_nacionalidade;
    },
    lista_cidades_endereco() {
      return this.$store.state.configEnderecos.lista_cidades_endereco;
    },
    lista_cidades_nacionalidade() {
      return this.$store.state.configEnderecos.lista_cidades_nacionalidade;
    },
    lista_bairros_endereco() {
      return this.$store.state.configEnderecos.lista_bairros_endereco;
    },
    mensagem_alert() {
      return this.$store.state.configUsuarios.mensagem_alert;
    },
    lista_profissoes() {
      return this.$store.state.configUsuarios.lista_profissoes;
    },
    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },

  },
  created() {
    this.listar_perfil();
    this.preenxerCampos();
    if (this.form.naturalidade_pais > 0) {
      this.getEstadosNacionalidade(this.form.naturalidade_pais)
    }
    if (this.form.pais_id > 0) {
      this.getEstadosEndereco(this.form.pais_id)
    }
    if (this.form.naturalidade_estado > 0) {
      this.getCidadesNacionalidade(this.form.naturalidade_estado)
    }
    if (this.form.estado_id > 0) {
      this.getCidadesEndereco(this.form.estado_id)
    }
    if (this.form.cidade_id > 0 && this.pais_id == 3) {
      this.lista_cidades_endereco.forEach(e => {
        if (e.id == this.form.cidade_id) {
          this.getBairrosEndereco(e.codigo_departamento, e.codigo_distrito)
        }
      });

    }
  },
  methods: {
    async calcularIdade(value) {
      var hoje = new Date();
      var dataNascimento = new Date(value);

      var idade = hoje.getFullYear() - dataNascimento.getFullYear();
      var meses = hoje.getMonth() - dataNascimento.getMonth();

      if (hoje.getDate() < dataNascimento.getDate()) {
        meses--;
      }

      if (meses < 0 || (meses === 0 && hoje.getDate() < dataNascimento.getDate())) {
        idade--;
        meses = 12 + meses;
      }

      console.log(`Edad: ${idade} años y ${meses} meses`);

      this.idade = ` ${idade} años y ${meses} meses`;
    },

    async listar_perfil() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      this.$store.dispatch("configUsuarios/listar_profissoes");
      this.$store.dispatch("configEnderecos/all_paises");
      await this.$store.dispatch("configEmpresa/listar_perfil").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        // this.$store.dispatch("atualizar", "");
      });
    },

    async getEstadosNacionalidade(value) {
      await this.$store.dispatch("configEnderecos/getEstadosNacionalidade", value);
      console.log(this.lista_estados_nacionalidade)
    },
    async getEstadosEndereco(value) {
      await this.$store.dispatch("configEnderecos/getEstadosEndereco", value);
    },

    async getCidadesNacionalidade(value) {
      await this.$store.dispatch("configEnderecos/getCidadesNacionalidade", value);
      console.log(this.lista_cidades_nacionalidade)
    },
    async getCidadesEndereco(value) {
      await this.$store.dispatch("configEnderecos/getCidadesEndereco", value);
    },
    async getBairrosEndereco(codigo_departamento, codigo_distrito) {
      this.bairro_id = null
      const dados = {
        codigo_departamento: codigo_departamento,
        codigo_distrito: codigo_distrito
      }
      await this.$store.dispatch("configEnderecos/getBairros", dados);

    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta acion  ` + tipo + ` usuarios en el sistema`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
            if (tipo == "cria una profission para") this.create_profissao();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("configUsuarios/create_usuario", this.form);
      this.verif = false;
      console.log(this.mensagem_alert);
      if (this.mensagem_alert.tipo == "error") {
        Swal.fire({
          title: "Problema con los datos!",
          text: this.mensagem_alert.message.message,
          footer:'Certifiquese de llenar todos los campos obrigatórios marcados con *',
          confirmButtonText: "Entendido",
          confirmButtonColor: "#ff6060",
          // customClass: {
          //   icon: "center",
          // },
        });
      } else {
        this.fireAlert({
          ...this.mensagem_alert,
          routeName: "gerenPaciente",
        });
      }
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("configUsuarios/update_usuario", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "gerenPaciente",
      });
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          sobrenome: this.lista_campos.sobrenome,
          possui_documento: this.lista_campos.possui_documento,
          bairro_id: this.lista_campos.bairro_id,
          nome_sobrenome: this.lista_campos.nome_sobrenome,
          data_nascimento: this.lista_campos.data_nascimento,
          cpf: this.lista_campos.cpf,
          rg: this.lista_campos.rg,
          sexo_id: this.lista_campos.sexo_id,
          email: this.lista_campos.email,
          status: this.lista_campos.status,
          profissao_id: this.lista_campos.profissao_id,
          cep: this.lista_campos.cep,
          rua: this.lista_campos.rua,
          bairro: this.lista_campos.bairro,
          naturalidade_pais: this.lista_campos.naturalidade_pais,
          naturalidade_estado: this.lista_campos.naturalidade_estado,
          naturalidade_cidade: this.lista_campos.naturalidade_cidade,
          pais_id: this.lista_campos.pais_id,
          cidade_id: this.lista_campos.cidade_id,
          estado_id: this.lista_campos.estado_id,
          telefone1: this.lista_campos.telefone1,
          telefone2: this.lista_campos.telefone2,
          perfil_id: this.lista_campos.perfil_id,
          tipo_paciente_id: this.lista_campos.tipo_paciente_id,
          numero: this.lista_campos.numero,
          login: this.lista_campos.login

        };
        console.log(this.form)
    },
    async create_profissao() {
      this.verif = true;
      await this.$store.dispatch(
        "configUsuarios/create_profissao",
        this.form_profissao
      );
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
      this.form.profissao_id = this.lista_profissoes[this.lista_profissoes.length - 1].id;
      console.log(this.form.profissao_id);
      this.$refs["Profissao"].hide();
    },
    searchProf({ searchQuery, callback }) {
      let data = this.$store.state.configUsuarios.lista_profissoes.filter(
        (item) => {
          if (!searchQuery) return true;
          return (
            String(item["label"])
              .toLowerCase()
              .indexOf(searchQuery.toLowerCase()) !== -1
          );
        }
      );

      console.log(data);
      let options = data.map((usuario) => ({
        id: usuario.id,
        label: usuario.label,
      }));
      callback(null, options);
    },
  },
};
</script>
  
<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>